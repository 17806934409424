export type EventData<T = unknown> = Record<string, T>

export interface Tracker {
  trackPageView: (data: EventData) => void
}

export class Analytics {
  #trackers: Tracker[] = []

  registerTracker(tracker: Tracker): void {
    this.#trackers.push(tracker)
  }

  trackPageView(data: EventData): void {
    this.#trackers.forEach((tracker) => tracker.trackPageView(data))
  }
}
