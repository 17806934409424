import React, { ReactElement } from 'react'

import { SelectChangeEvent } from '@mui/material'

import { useSetLocale } from '../../../../../../providers/I18nProvider/hooks/use-set-locale'
import { Locale } from '../../../../../../shared/i18n'
import { Select } from '../../../../../Select'
import { LOCALES } from './constants'

export function LocaleSelector(): ReactElement {
  const { initLocale, setLocale } = useSetLocale()

  const handleChange = (event: SelectChangeEvent<string | number>): void => {
    setLocale(event.target.value as Locale)
  }

  return <Select defaultValue={initLocale} items={LOCALES} sx={{ width: 'fit-content' }} onChange={handleChange} />
}
