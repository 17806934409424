import React from 'react'

import Bugsnag, { BrowserConfig, Event } from '@bugsnag/js'
import BugsnagPluginReact, { type BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import invariant from 'tiny-invariant'

import { config } from '../config'

const enabledReleaseStages = ['staging', 'production']

const bugsnagConfig: BrowserConfig = {
  apiKey: config.common.bugsnagApiKey,
  autoDetectErrors: true,
  enabledErrorTypes: {
    unhandledExceptions: true,
    unhandledRejections: true,
  },
  enabledReleaseStages,
  onError: handleError,
  releaseStage: config.common.environment,
}

export function bugsnagInit(): BugsnagErrorBoundary {
  Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginReact()],
  })

  const bugsnagReactPlugin = Bugsnag.getPlugin('react')

  invariant(bugsnagReactPlugin, 'Bugsnag React plugin is not available')

  return bugsnagReactPlugin.createErrorBoundary(React)
}

function handleError(event: Event): void {
  addMetadata(event)
}

function addMetadata(event: Event): void {
  event.addMetadata('whiteLabel', { value: config.whitelabel })
}
