import { i18n, LocaleData } from '@lingui/core'
import { ar, en } from 'make-plural/plurals'
import invariant from 'tiny-invariant'

import { config } from '../../config'
import { defaultLocale, Locale, LOCALE_STORAGE_KEY, locales, Translate } from './constants'

const AllowedLocales = Object.keys(locales)

const plurals: { [K in Locale]: LocaleData } = { ar: { plurals: ar }, en: { plurals: en } }

const isValidLocale = (value: unknown): value is Locale => AllowedLocales.includes(value as Locale)

export function getInitialLocale(): Locale {
  try {
    const localStorageLang = localStorage.getItem(LOCALE_STORAGE_KEY)
    if (localStorageLang != null && isValidLocale(localStorageLang)) return localStorageLang
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LocalStorage is not available')
  }

  const deviceLocale = navigator.language.slice(0, 2)

  if (isValidLocale(deviceLocale)) return deviceLocale

  return defaultLocale
}

export function activateLocale(locale: Locale): void {
  invariant(isValidLocale(locale))

  try {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LocalStorage is not available')
  }

  const { localeData, messages } = getLocale(locale)

  i18n.load({ [locale]: messages })
  i18n.loadLocaleData({ [locale]: localeData })
  i18n.activate(locale)
}

function getLocale(locale: Locale): Translate {
  const currentLocale = config.locales[locale]

  invariant(currentLocale, `Locale ${locale} is not supported`)
  const localeData = getLocaleData(locale)

  return { ...currentLocale, localeData }
}

function getLocaleData(locale: Locale): LocaleData {
  return plurals[locale] ?? plurals[defaultLocale]
}
