import React, { FunctionComponent, ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Box, Link, Stack } from '@mui/material'

import { ReactComponent as Android } from './assets/download_android.svg'
import { ReactComponent as Apple } from './assets/download_apple.svg'
import { ReactComponent as Windows } from './assets/download_windows.svg'
import { Text } from './styles'
import { getDownloadLinks } from './utils'

type LogoProps = {
  icon: FunctionComponent<React.SVGProps<SVGSVGElement>>
  href: string
}

const { androidDownloadLink, appleDownloadLink, windowsDownloadLink } = getDownloadLinks()

export function MT4Platform(): ReactElement {
  return (
    <Stack direction="column" gap={0.75}>
      <Text>
        <Trans>Download MT4 Platform</Trans>
      </Text>
      <Stack direction="row" gap={1.25}>
        <Logo href={windowsDownloadLink} icon={Windows} />
        <Logo href={appleDownloadLink} icon={Apple} />
        <Logo href={androidDownloadLink} icon={Android} />
      </Stack>
    </Stack>
  )
}

const Logo = ({ href, icon }: LogoProps): ReactElement => (
  <Link href={href}>
    <Box component={icon} />
  </Link>
)
