import invariant from 'tiny-invariant'

import { WhiteLabel } from '../../config/white-label-config'
import { config } from '../config'

const COMPLAINTS_EMAILS: Partial<Record<WhiteLabel, string>> = {
  tauroMarkets: 'complaints@tauromarkets.com',
  tauroMarketsMauritius: 'complaints@mau.tauromarkets.com',
}

const SUPPORT_EMAILS: Partial<Record<WhiteLabel, string>> = {
  tauroMarkets: 'support@tauromarkets.com',
  tauroMarketsMauritius: 'support@mau.tauromarkets.com',
}

export function getSupportEmails(): string {
  const email = SUPPORT_EMAILS[config.whitelabel]

  invariant(email)

  return email
}

export function getComplaintsEmails(): string {
  const email = COMPLAINTS_EMAILS[config.whitelabel]

  invariant(email)

  return email
}
