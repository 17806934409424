import { config } from '../config'
import { websiteRoutes } from './constants'

const JOIN_LINK = new URL(`${config.urls.dashboardUrl}/register`)
const AFFILIATE_REGISTER_LINK = new URL(`${config.urls.dashboardUrl}/register/affiliate`)

export function getJoinLink(): string {
  if (config.availableForRegistration) {
    return JOIN_LINK.toString()
  }

  return websiteRoutes.registrationUnavailable
}

export function getAffiliateRegisterLink(): string {
  if (config.availableForRegistration) {
    return AFFILIATE_REGISTER_LINK.toString()
  }

  return websiteRoutes.registrationUnavailable
}
