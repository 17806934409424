import React, { ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import { ButtonProps, Button } from '@mui/material'

import { getJoinLink } from '../../shared/get-join-link'

type JoinNowButtonProps = ButtonProps & {
  children?: ReactNode
  href?: string
}

export function JoinNowButton(props: JoinNowButtonProps): ReactElement {
  const { children = <Trans>Join Now</Trans>, href = getJoinLink(), ...rest } = props
  return (
    <Link to={href}>
      <Button variant="contained" {...rest}>
        {children}
      </Button>
    </Link>
  )
}
