import React, { ReactElement } from 'react'

import { ErrorBoundaryWrapper } from './components/ErrorBoundaryWrapper'
import { I18nProvider } from './providers/I18nProvider'
import { LiveChatProvider } from './providers/LiveChatProvider'
import { RestClientProvider } from './providers/RestClientProvider'
import { SnackbarProvider } from './providers/SnackbarProvider'
import { ThemeProvider } from './providers/ThemeProvider'
import { RootScene } from './scenes'

export function App(): ReactElement {
  return (
    <ErrorBoundaryWrapper>
      <RestClientProvider>
        <I18nProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <LiveChatProvider>
                <RootScene />
              </LiveChatProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </I18nProvider>
      </RestClientProvider>
    </ErrorBoundaryWrapper>
  )
}
