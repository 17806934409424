import React, { memo, type ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import { analyticsInit } from '../../shared/analytics'

const analytics = analyticsInit()

type AnalyticsPageViewTriggerBaseProps = {
  path: string
}

function AnalyticsPageView({ path }: AnalyticsPageViewTriggerBaseProps): ReactNode {
  analytics.trackPageView({ path })

  return null
}

const AnalyticsPageViewTriggerBase = memo(AnalyticsPageView)

export function AnalyticsPageViewTrigger(): ReactNode {
  const { pathname } = useLocation()
  return <AnalyticsPageViewTriggerBase path={pathname} />
}
