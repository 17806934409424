import React, { lazy, ReactElement } from 'react'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

import { CommonLayout } from '../components/CommonLayout'
import { SomethingWentWrongPage } from '../components/SomethingWentWrongPage'
import { websiteRoutes } from '../shared/constants'
import { ROUTE as EducationPagesRoute } from './EducationPages'
import { ROUTE as FaqRoute } from './Faq'

const AboutUs = lazy(() => import('./AboutUs'))
const ContactUs = lazy(() => import('./ContactUs'))
const Home = lazy(() => import('./Home'))
const PageNotFound = lazy(() => import('./PageNotFound'))
const Partners = lazy(() => import('./Partners'))
const LegalDocuments = lazy(() => import('./LegalDocuments'))
const RegistrationUnavailable = lazy(() => import('./RegistrationUnavailable'))

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<CommonLayout />} errorElement={<SomethingWentWrongPage />} path={websiteRoutes.home}>
        <Route errorElement={<SomethingWentWrongPage />}>
          <Route element={<Home />} index />
          <Route element={<AboutUs />} path={websiteRoutes.about} />
          <Route element={<Partners />} path={websiteRoutes.partners} />
          <Route element={<ContactUs />} path={websiteRoutes.contacts} />
          <Route element={<LegalDocuments />} path={websiteRoutes.legalDocuments} />
          <Route element={<RegistrationUnavailable />} path={websiteRoutes.registrationUnavailable} />
          <Route element={<PageNotFound />} path="*" />
          {EducationPagesRoute}
          {FaqRoute}
        </Route>
      </Route>
    </>,
  ),
)

export function RootScene(): ReactElement {
  return <RouterProvider router={router} />
}
