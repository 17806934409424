import { config } from '../config'
import { websiteRoutes } from './constants'

const LOGIN_LINK = new URL(`${config.urls.dashboardUrl}`)

export function getLogInLink(): string {
  if (config.availableForLogin) {
    return LOGIN_LINK.toString()
  }

  return websiteRoutes.registrationUnavailable
}
