import { Box, Stack, styled } from '@mui/material'

import { theme } from '../../providers/ThemeProvider/theme'

export const Container = styled(Stack, { shouldForwardProp: (prop) => prop !== 'imageSrc' })<{ imageSrc?: string }>(
  ({ imageSrc }) => ({
    alignItems: 'center',
    backgroundImage: `url(${imageSrc})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    gap: theme.spacing(4.5),
    height: '100vh',

    padding: '84px',

    [theme.breakpoints.down('md')]: {
      padding: ' 65px 16px',
    },

    width: '100%',
  }),
)

export const Image = styled(Box)({
  flex: '1 1 0',
  minHeight: '0',
  padding: '0 0 144px  0',
  position: 'relative',
}) as typeof Box
