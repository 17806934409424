import { Components } from '@mui/material'

export const buttons: Pick<Components, 'MuiButton' | 'MuiButtonBase'> = {
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      contained: {
        ':active': {
          border: '2px solid',
          boxShadow: 'none',
        },
        ':disabled': {
          backgroundColor: 'rgba(242, 244, 247, 1)',
          border: '1px solid #E4E7EC',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 32, 0.05)',
          color: ' rgba(152, 162, 179, 1))',
        },
        ':hover': {
          backgroundColor: 'rgba(1, 71, 177, 1)',
          boxShadow: 'none',
        },
        border: '2px solid',
        borderImageSource: 'linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)',
        padding: '8px 18px',
      },
      outlined: {
        ':active': {
          border: '2px solid #155EEF',
          boxShadow: 'none',
        },
        ':hover': {
          backgroundColor: 'rgba(1, 71, 177, 1)',
          border: '2px solid #155EEF',
          boxShadow: 'none',
          color: '#FFFFFF',
        },
        backgroundColor: 'transparent',
        border: '2px solid #155EEF',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 32, 0.05)',
        color: '#155EEF',
        padding: '8px 18px',
      },
      root: {
        backgroundColor: '#155EEF',
        borderRadius: '8px',
        boxShadow:
          '0px 1px 2px 0px rgba(16, 24, 32, 0.05), 0px -2px 0px 0px rgba(16, 24, 32, 0.05) inset, 0px 0px 0px 1px rgba(16, 24, 32, 0.05) inset',
        boxSizing: 'border-box',
        color: '#FFFFFF',
        gap: '10px',
        padding: '10px 18px',
      },
      sizeSmall: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '19px',
        maxHeight: '34px',
        padding: '8px 12px',
      },
      text: {
        ':hover': { backgroundColor: 'rgba(21, 94, 239, 0.12)', boxShadow: 'none' },
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: '#3A3A3A',
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
}
