import { config } from '../../config'
import { Analytics } from './analitics'
import { GoogleTagManager } from './google-tag-manager'

export function analyticsInit(): Analytics {
  const analytics = new Analytics()
  const gtmId = config.googleTagManagerID

  if (gtmId) {
    const googleTagManager = new GoogleTagManager(gtmId)
    analytics.registerTracker(googleTagManager)
  }

  return analytics
}
