import React, { ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Trans } from '@lingui/macro'
import { Button, Stack, Typography } from '@mui/material'

import { Container, Image } from './styles'

type SomethingWentWrongPageProps = {
  className?: string
  renderAction?: ReactNode
  children?: ReactNode
  imageSrc?: string
  backgroundImageSrc?: string
}

export function SomethingWentWrongPage(props: SomethingWentWrongPageProps): ReactElement {
  const { backgroundImageSrc, children = defaultContent, className, imageSrc, renderAction = defaultAction } = props

  return (
    <Container className={className} imageSrc={backgroundImageSrc}>
      {children}
      {renderAction}
      {imageSrc != null ? <Image component={'img'} src={imageSrc} /> : null}
    </Container>
  )
}

const defaultAction = (
  <Link to={'/'}>
    <Button variant="contained" fullWidth>
      <Trans>Go back to Homepage</Trans>
    </Button>
  </Link>
)

const defaultContent = (
  <Stack alignItems="center" gap={2}>
    <Typography variant="h6">
      <Trans>Something went wrong</Trans>
    </Typography>
  </Stack>
)
