import React, { ReactNode } from 'react'

import { bugsnagInit } from '../../shared/bugsnag'
import { FallbackComponent } from './components/FallbackComponent'

type ErrorBoundaryProviderProps = {
  children: ReactNode
}

const BugsnagErrorBoundaryBase = bugsnagInit()

export function ErrorBoundaryWrapper({ children }: ErrorBoundaryProviderProps): ReactNode {
  return <BugsnagErrorBoundaryBase FallbackComponent={FallbackComponent}>{children}</BugsnagErrorBoundaryBase>
}
