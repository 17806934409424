import { LocaleData, Messages } from '@lingui/core'

export const LOCALE_STORAGE_KEY = 'locale'

export type Locale = keyof typeof locales

export type Translate = { messages: Messages; localeData: LocaleData }

export const locales = {
  ar: 'Arabic',
  en: 'English',
}
export const defaultLocale: Locale = 'en'
