const LOADED_SCRIPTS = new Set<string>()

const DEFAULT_TARGET = 'head'

export type MountScriptInputType = {
  scriptId?: string
  scriptSrc: string
  textContent?: string
  targetId?: string
}

type MountScriptReturnedType = {
  unmountCb: () => void
}

export function mountScript({
  scriptId,
  scriptSrc,
  targetId = DEFAULT_TARGET,
  textContent,
}: MountScriptInputType): MountScriptReturnedType | void {
  if (!window || !document) return

  if (LOADED_SCRIPTS.has(scriptSrc)) return

  const targetToInsertElement =
    targetId === DEFAULT_TARGET ? document.getElementsByTagName(targetId).item(0) : document.getElementById(targetId)

  if (!targetToInsertElement) return

  const $script = document.createElement('script')
  if (scriptId != null) $script.setAttribute('id', scriptId)
  $script.src = scriptSrc
  $script.type = 'text/javascript'
  $script.async = true

  if (textContent != null) $script.innerHTML = textContent

  targetToInsertElement.appendChild($script)

  LOADED_SCRIPTS.add(scriptSrc)

  return {
    unmountCb: (): void => {
      targetToInsertElement.removeChild($script)
      LOADED_SCRIPTS.delete(scriptSrc)
    },
  }
}
