import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { websiteRoutes } from '../../shared/constants'
import { renderRouteProps } from '../../shared/render-route-props'

const AdvancedEducation = lazy(() => import('./Advanced'))
const BeginnerEducation = lazy(() => import('./Beginner'))
const DailyAnalysis = lazy(() => import('./DailyAnalysis'))
const EducationTools = lazy(() => import('./EducationTools'))
const IntermediateEducation = lazy(() => import('./Intermediate'))
const VideoCourses = lazy(() => import('./VideoCourses'))

export const ROUTE = (
  <Route
    {...renderRouteProps({
      crumb: { crumbComponent: <Trans>Education</Trans>, isClickable: false },
      path: websiteRoutes.education,
    })}
  >
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Beginner Trading</Trans> },
        element: <BeginnerEducation />,
        path: websiteRoutes.educationBeginner,
      })}
    />
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Beginner Trading</Trans> },
        element: <VideoCourses />,
        param: 'courseName',
        path: websiteRoutes.educationBeginner,
      })}
    />
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Intermediate Trading</Trans> },
        element: <IntermediateEducation />,
        path: websiteRoutes.educationIntermediate,
      })}
    />
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Intermediate Trading</Trans> },
        element: <VideoCourses />,
        param: 'courseName',
        path: websiteRoutes.educationIntermediate,
      })}
    />
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Advanced Trading</Trans> },
        element: <AdvancedEducation />,
        path: websiteRoutes.educationAdvanced,
      })}
    />
    <Route
      {...renderRouteProps({
        crumb: { crumbComponent: <Trans>Advanced Trading</Trans> },
        element: <VideoCourses />,
        param: 'courseName',
        path: websiteRoutes.educationAdvanced,
      })}
    />
    <Route element={<EducationTools />} path={websiteRoutes.educationTools} />
    <Route element={<DailyAnalysis />} path={websiteRoutes.educationDailyAnalysis} />
  </Route>
)
