import React, { ReactElement } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Trans } from '@lingui/macro'

import { config } from '../../../../../../config'
import { websiteRoutes } from '../../../../../../shared/constants'
import { getComplaintsEmails } from '../../../../../../shared/get-emails'
import { Text } from '../../styled'
import { Link, TextTitle } from './styles'

const complaintsEmail = getComplaintsEmails()

const TAUROMARKET_LINK = config.urls.frontendUrl

export function Content(): ReactElement {
  return (
    <>
      <TextTitle textAlign="left">
        <Trans>Risk Warning</Trans>
      </TextTitle>
      <Text textAlign="left">
        <Trans>
          Leveraged trading in foreign currency contracts, contracts for difference or other off-exchange products
          carries a high level of risk and may not be suitable for everyone. Before trading, you are strongly advised to
          read and ensure that you understand the relevant risk disclosures. There is a substantial risk that you may
          lose all of your initial investment. We advise you to consider whether trading leveraged products is
          appropriate for you in light of your own personal circumstances. We recommend that you seek independent
          financial advice and ensure that you fully understand all risks involved before trading.
        </Trans>
      </Text>
      <TextTitle textAlign="left">
        <Trans>Legal Information</Trans>
      </TextTitle>
      <Text textAlign="left">
        <Trans>
          Tauro Markets (<Link href={TAUROMARKET_LINK}>TAUROMARKETS.COM</Link>) is the trading name owned by JM
          Financial Services Co. Which is authorized by the Ministry of Commerce in the State of Kuwait bearing
          registration No. 355893. and registered office: Qibla, Abdullah Al Mubarak, Al Enmaa’ Tower, 15 Floor, Kuwait
          City, Kuwait.
        </Trans>
      </Text>
      <Text textAlign="left">
        <Trans>
          Tauro Markets (<Link href={TAUROMARKET_LINK}>TAUROMARKETS.COM</Link>) is the trading name operated by JM
          Financial L.L.C , JM Financial LLC is a brokerage services firm providing Forex & CFD trading. The company is
          based in Suite 305, Griffith Corporate Centre, Beachmont, Kingstown, Saint Vincent & the Grenadines
          (Corporation number 2496 LLC 2022).
        </Trans>
      </Text>
      <TextTitle textAlign="left">
        <Trans>Restricted Jurisdictions</Trans>
      </TextTitle>
      <Text textAlign="left">
        <Trans>
          We do not establish accounts to residents of certain jurisdictions including Belgium, Democratic Republic of
          Congo, Eritrea, Iran, Democratic People’s Republic of Korea, Libyan Arab Jamahiriya, Somalia, Sudan, Syrian
          Arab Republic, Israel, United States (and US reportable persons) or any particular country or jurisdiction
          where such distribution or use would be contrary to local law or regulation.
        </Trans>
      </Text>
      <Text textAlign="left">
        <Trans>
          Please review the company policies and legal documents{' '}
          <RouterLink to={websiteRoutes.legalDocuments}>here</RouterLink>. <br />
          For complaints please email us at <Link href={`mailto:${complaintsEmail}`}>{complaintsEmail}</Link>
        </Trans>
      </Text>
    </>
  )
}
