import { mountScript } from '../mount-script'
import { EventData, Tracker } from './analitics'

const GTM_DATA_LAYER = 'dataLayer'

export const enum EventTypes {
  PageView = 'PageView',
}

export class GoogleTagManager implements Tracker {
  #googleTagManagerId: string

  constructor(readonly googleTagManagerId: string) {
    this.#googleTagManagerId = googleTagManagerId

    this.init()
  }

  trackPageView(data: EventData): void {
    this.trackEvent(EventTypes.PageView, data)
  }

  private init(): void {
    loadScript(this.#googleTagManagerId)

    this.initDataLayer()
  }

  private initDataLayer(): void {
    getEventQueue().push({ event: 'gtm.js', 'gtm.start': new Date().getTime() })
  }

  private trackEvent(event: EventTypes, data: EventData): void {
    getEventQueue().push({ event, ...data })
  }
}

const loadScript = (googleTagManagerId: string): void => {
  mountScript({
    scriptSrc: `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}&l=${GTM_DATA_LAYER}`,
  })
}

const getEventQueue = (): unknown[] => {
  return (window[GTM_DATA_LAYER] = Array.isArray(window[GTM_DATA_LAYER]) ? window[GTM_DATA_LAYER] : [])
}

declare global {
  interface Window {
    [GTM_DATA_LAYER]: unknown[]
  }
}
