import { shouldUseMt5 } from '../../../../../../shared/should-use-mt5'
import {
  ANDROID_MT4_DOWNLOAD_LINK,
  ANDROID_MT5_DOWNLOAD_LINK,
  APPLE_MT4_DOWNLOAD_LINK,
  APPLE_MT5_DOWNLOAD_LINK,
  WINDOWS_MT4_DOWNLOAD_LINK,
  WINDOWS_MT5_DOWNLOAD_LINK,
} from './constants'

type GetDownloadLinksReturnedType = {
  windowsDownloadLink: string
  androidDownloadLink: string
  appleDownloadLink: string
}

export function getDownloadLinks(): GetDownloadLinksReturnedType {
  const isMt5 = shouldUseMt5()

  return {
    androidDownloadLink: isMt5 ? ANDROID_MT5_DOWNLOAD_LINK : ANDROID_MT4_DOWNLOAD_LINK,
    appleDownloadLink: isMt5 ? APPLE_MT5_DOWNLOAD_LINK : APPLE_MT4_DOWNLOAD_LINK,
    windowsDownloadLink: isMt5 ? WINDOWS_MT5_DOWNLOAD_LINK : WINDOWS_MT4_DOWNLOAD_LINK,
  }
}
